export enum HostChannelEvent {
  START_BROADCAST = 'START_BROADCAST',
  STOP_BROADCAST = 'STOP_BROADCAST',

  RAISED_HAND_REQUEST_ADDED = 'RAISED_HAND_REQUEST_ADDED',
  RAISED_HAND_REQUEST_CANCELED = 'RAISED_HAND_REQUEST_CANCELED',

  AUDIENCE_GALLERY_REQUEST_ADDED = 'AUDIENCE_GALLERY_REQUEST_ADDED',
  AUDIENCE_GALLERY_REQUEST_CANCELED = 'AUDIENCE_GALLERY_REQUEST_CANCELED',

  CONTRIBUTOR_VIEW_REQUEST_ADDED = 'CONTRIBUTOR_VIEW_REQUEST_ADDED',
  CONTRIBUTOR_VIEW_REQUEST_CANCELED = 'CONTRIBUTOR_VIEW_REQUEST_CANCELED',

  CHAT = 'CHAT',
  DELETE_CHAT = 'DELETE_CHAT',

  MOVE_STAGE = 'MOVE_STAGE',
  MOVE_GREEN_ROOM = 'MOVE_GREEN_ROOM',

  ROLL_CALL_BACKSTAGE = 'ROLL_CALL_BACKSTAGE',
  AV_REMINDER = 'AV_REMINDER',

  MUTE_USER = 'MUTE_USER',
  UNMUTE_USER = 'UNMUTE_USER',

  STAGE_BREAKOUT = 'STAGE_BREAKOUT',
  STAGE_BRINGBACK = 'STAGE_BRINGBACK',
  STAGE_SETTINGS_UPDATE = 'STAGE_SETTINGS_UPDATE',
  STAGE_SESSIONS_UPDATE = 'STAGE_SESSIONS_UPDATE',
  CHAT_REACTION = 'CHAT_REACTION',
  UPDATE_CHANNEL_SETTING = 'UPDATE_CHANNEL_SETTING',
}

export enum AttendeeChannelEvent {
  REACTION = 'REACTION',
  RAISED_HAND_REQUEST_ACCEPTED = 'RAISED_HAND_REQUEST_ACCEPTED',
  RAISED_HAND_REQUESTS_DISMISSED = 'RAISED_HAND_REQUESTS_DISMISSED',
  RAISED_HAND_REQUEST_COMPLETED = 'RAISED_HAND_REQUEST_COMPLETED',

  AUDIENCE_GALLERY_REQUEST_ACCEPTED = 'AUDIENCE_GALLERY_REQUEST_ACCEPTED',
  AUDIENCE_GALLERY_REQUESTS_DISMISSED = 'AUDIENCE_GALLERY_REQUESTS_DISMISSED',
  AUDIENCE_GALLERY_REQUEST_COMPLETED = 'AUDIENCE_GALLERY_REQUEST_COMPLETED',

  CONTRIBUTOR_VIEW_REQUEST_ACCEPTED = 'CONTRIBUTOR_VIEW_REQUEST_ACCEPTED',
  CONTRIBUTOR_VIEW_REQUEST_DISMISSED = 'CONTRIBUTOR_VIEW_REQUEST_DISMISSED',
  CONTRIBUTOR_VIEW_REQUEST_COMPLETED = 'CONTRIBUTOR_VIEW_REQUEST_COMPLETED',

  CHAT_VIRTUAL = 'CHAT_VIRTUAL',
  DELETE_CHAT = 'DELETE_CHAT',
  CHAT_PIN_STATUS_UPDATE = 'CHAT_PIN_STATUS_UPDATE',
  QUESTION_ASK_VIRTUAL = 'QUESTION_ASK_VIRTUAL',
  QUESTION_ANSWERING = 'QUESTION_ANSWERING',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  QUESTION_UPVOTE = 'QUESTION_UPVOTE',
  QUESTION_CANCEL_UPVOTE = 'QUESTION_CANCEL_UPVOTE',

  POLL_CREATE_VIRTUAL = 'POLL_CREATE_VIRTUAL',
  POLL_UPDATE_VIRTUAL = 'POLL_UPDATE_VIRTUAL',
  POLL_VOTE = 'POLL_VOTE',
  POLL_CLEAR_VOTE = 'POLL_CLEAR_VOTE',
  POLL_DELETE = 'POLL_DELETE',

  START_BROADCAST = 'START_BROADCAST',
  STOP_BROADCAST = 'STOP_BROADCAST',
  UPDATE_VIDEO_STATUS = 'UPDATE_VIDEO_STATUS',
  KICKOFF = 'KICKOFF',
  MOVE_STAGE = 'MOVE_STAGE',
  MOVE_GREEN_ROOM = 'MOVE_GREEN_ROOM',

  UPDATE_TIMER = 'UPDATE_TIMER',
  STOP_TIMER = 'STOP_TIMER',

  CONFETTI_START = 'CONFETTI_START',
  CONFETTI_STOP = 'CONFETTI_STOP',

  BADGE_WON = 'BADGE_WON',
  CHANNEL_IMAGE_UPDATE = 'CHANNEL_IMAGE_UPDATE',

  LIVE_STREAM_START = 'LIVE_STREAM_START',
  LIVE_STREAM_STOP = 'LIVE_STREAM_STOP',

  NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT',
  STOP_ANNOUNCEMENT = 'STOP_ANNOUNCEMENT',
  STAGE_BREAKOUT = 'STAGE_BREAKOUT',
  STAGE_BRINGBACK = 'STAGE_BRINGBACK',

  REMOVE_USER_STREAM_PRESENCE = 'client-REMOVE_USER_STREAM_PRESENCE',
  UPDATE_CHANNEL_SETTING = 'UPDATE_CHANNEL_SETTING',

  STOP_SCREEN_SHARE_REMOTE_USER = 'STOP_SCREEN_SHARE_REMOTE_USER',
  MUTE_REMOTE_USER = 'MUTE_REMOTE_USER',
  UNMUTE_REQUEST_REMOTE_USER = 'UNMUTE_REQUEST_REMOTE_USER',
  VIDEO_OFF_REMOTE_USER = 'VIDEO_OFF_REMOTE_USER',
  VIDEO_ON_REQUEST_REMOTE_USER = 'VIDEO_ON_REQUEST_REMOTE_USER',

  CHANNEL_REQUEST_RAISE_HAND = 'CHANNEL_REQUEST_RAISE_HAND',
  CHANNEL_REQUEST_LOWER_HAND = 'CHANNEL_REQUEST_LOWER_HAND',
  SURVEY_PUBLISH = 'SURVEY_PUBLISH',

  LIVE_STAGES_STATE = 'LIVE_STAGES_STATE',
  LITE_MODE_UPDATE = 'LITE_MODE_UPDATE',
  CHAT_REACTION = 'CHAT_REACTION',

  START_DRY_RUN = 'START_DRY_RUN',
  STOP_DRY_RUN = 'STOP_DRY_RUN',
}

export const OrganizationChannelEvent = {
  ORG_PLAN_RESET: 'ORG_PLAN_RESET',
} as const;
